import { InputBase, Typography, styled } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ArrowRight } from 'react-feather';
import { Button } from '@cognassist/react-components';
import { ErrorMessage } from '@hookform/error-message';
import { Field } from '../../components/Field';
import Footer from '../../components/footer';
import Logo from '../../assets/img/logo.svg';
import { shouldForwardProps } from '../../utils/shouldForwardProp';
import { useEndpoints } from '../../custom-hooks/useEndpoints';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AssessmentResultStatus } from '../../types/enums';

const Wrapper = styled('div')(() => ({
    background: '#EDEDEF',
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const Card = styled('div')(({ theme }) => ({
    width: 420,
    borderRadius: 16,
    boxShadow: theme.cogShadows.xl,
    background: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    padding: theme.spacing(14, 4),
    [theme.breakpoints.down(480)]: {
        minWidth: 0,
        width: '100%',
        borderRadius: 0,
        minHeight: '100vh',
        justifyContent: 'flex-start',
    },
}));

const CogLogo = styled('img')(({ theme }) => ({
    maxWidth: 250,
    width: '100%',
    marginBottom: theme.spacing(4),
}));

const ErrorText = styled('p')(({ theme }) => ({
    marginTop: 4,
    color: theme.palette.error.main,
    wordBreak: 'break-word',
}));

const CogInput = styled(InputBase, {
    ...shouldForwardProps('hasError'),
})<{ hasError?: boolean }>(({ hasError, theme }) => ({
    width: '100%',
    '& .MuiInputBase-input': {
        fontSize: 20,
        padding: '10px 14px',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        border: hasError
            ? `2px solid ${theme.palette.error.main}`
            : `2px solid ${theme.palette.grey[300]}`,
        '&:focus': {
            borderColor: hasError
                ? theme.palette.error.main
                : theme.palette.primary.main,
        },
    },
}));

interface IFormFields {
    email: string;
}

export const Login = () => {
    const { validateEmail, resendRegistrationInvite } = useEndpoints();
    const { enqueueSnackbar } = useSnackbar();
    const [searchParams] = useSearchParams();

    const [submitError, setSubmitError] = useState('');

    const methods = useForm<IFormFields>({
        defaultValues: {
            email: '',
        },
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = methods;

    const getReturnUrl = (callbackUri: string, email: string) => {
        let returnUrl = callbackUri;
        let returnPath = window.location.search.replace('?returnPath=', '');

        if (returnPath) {
            if (returnPath.startsWith('/') && callbackUri.endsWith('/')) {
                returnPath = returnPath.substring(1);
            }

            returnUrl = returnUrl + returnPath;
        }

        return returnUrl + `?email=${encodeURIComponent(email)}`;
    };

    const onSubmit: SubmitHandler<IFormFields> = async (formData) => {
        const token = searchParams.get('token')
            ? searchParams.get('token')?.toString()
            : '';

        const operation = searchParams.get('operation')
            ? searchParams.get('operation')?.toString()
            : '';

        const { data, error } = await validateEmail(formData.email, operation);

        if (data) {
            if (data.isRegistered) {
                let returnUrl = '';

                if (token !== '') {
                    returnUrl = data.callbackUri + `?token=${token}`;
                } else {
                    returnUrl = getReturnUrl(data.callbackUri, formData.email);
                }

                window.location.href = returnUrl;
            } else {
                if (
                    !data.isEnterprise &&
                    !data.hasConfigAppsAndEducationCognitiveMap &&
                    data.matchedStatus ===
                        AssessmentResultStatus.NoNeedIdentified
                ) {
                    const message =
                        'Your assessment results show no further action is needed on Cognassist. For a copy of your results, please contact your tutor.';
                    setSubmitError(message);
                } else {
                    if (!data.delayRegistrationInvite) {
                        await resendRegistrationInvite(
                            data.clientId,
                            data.loginId,
                            data.hasLearnerRole
                        );
                        setSubmitError(
                            'Your account has not yet been registered, please check your email for a new registration link'
                        );
                    } else {
                        const message = data.isEnterprise
                            ? 'Your account has not yet been registered, please contact your administrator'
                            : 'Your account has not yet been registered, please contact your training provider';
                        setSubmitError(message);
                    }
                }
            }
        } else if (error) {
            enqueueSnackbar(
                'Unable to get user details.  Please contact your administrator.',
                {
                    variant: 'error',
                }
            );
        }
    };

    const { ref, ...rest } = register('email', {
        maxLength: {
            value: 230,
            message:
                'You have entered too many characters, please reduce it and try again.',
        },
        required: "Can't be blank",
        pattern: {
            value: /^[a-zA-Z0-9!'#$%&*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!'#$%&*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-z0-9])?\.)+[a-zA-Z]{2,}$/,
            message: 'Please enter a valid email address',
        },
    });

    return (
        <Wrapper>
            <Card>
                <CogLogo src={Logo} alt='cognassist logo' />
                <Typography sx={{ mb: 4 }} variant='h2'>
                    Welcome back
                </Typography>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ width: '100%' }}
                >
                    <Field
                        label='Email address'
                        labelFor='emailAddress'
                        hasError={!!errors.email}
                    >
                        <>
                            <CogInput
                                {...rest}
                                ref={(e: HTMLInputElement) => {
                                    ref(e);
                                }}
                                id='emailAddress'
                                fullWidth
                                type='email'
                                hasError={!!errors.email}
                            />
                            <ErrorMessage
                                errors={errors}
                                name='email'
                                render={({ message }) => (
                                    <ErrorText>{message}</ErrorText>
                                )}
                            />
                            <ErrorText>{submitError}</ErrorText>
                        </>
                    </Field>
                    <Button
                        size='large'
                        fullWidth
                        sx={{ mt: 2 }}
                        text='Next'
                        endIcon={<ArrowRight />}
                        onClick={handleSubmit(onSubmit)}
                    />
                </form>
            </Card>
            <Footer />
        </Wrapper>
    );
};
